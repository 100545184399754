<template>
  <v-container fluid class="gradBackground px-12">

    <!-- Header -->
    <v-row>
      <v-col>
        <h2 class="mt-3 mb-0">Galería</h2>
      </v-col>
    </v-row>

    <v-row class="mt-0 mb-3">
      <v-col cols="12">
        <v-card class="titleUnderline" min-height="12px" elevation="1" />
      </v-col>
    </v-row>

    <!-- Data display -->
    <v-row v-if="!isLoading">

      <v-col v-if="gallery.length > 0" cols="12">
        <v-row>
          <v-col v-for="(item, albumIdx) in gallery" :key="item._id" cols="12">

            <v-card class="borderleftdecor" elevation="5">

              <v-card-title>
                <v-avatar size="45" tile class="mr-3">
                  <img
                    src="@/assets/logoGalpon.png"
                  >
                </v-avatar>
                {{item.name}}
                </v-card-title>

              <v-divider></v-divider>

              <v-card-subtitle> <strong>Fecha:</strong> {{item.dateRange}}</v-card-subtitle>

              <v-card-text>

                <v-row>
                  <v-col cols="12">
                    {{item.description}}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-for="(img, idx) in item.imgURL" :key="idx" lg="3" md="4" sm="6" xs="12">
                    <v-img :src="`${apiURL}/uploads/${img}`" @click="openLightbox(`${apiURL}/uploads/${img}`, idx, albumIdx)" max-height="300" contain class="pointer" />
                  </v-col>
                </v-row>
              </v-card-text>
              
            </v-card>

          </v-col>
        </v-row>
      </v-col>

      <v-col v-else sm="12">
        <v-alert type="info" elevation="5" class="my-5" >No existen galerías por el momento</v-alert>
      </v-col>

    </v-row>

    <LoadingIndicator v-else />

    <!-- Image lightbox -->
    <v-overlay :value="isLightbox">

      <v-row fluid>
        <v-col align="center">

          <v-img :src="lightboxImg" contain class="maxwscreen" />

          <v-btn color="primary" @click="prevImage()" class="my-3 mr-2" >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn color="primary" @click="isLightbox = false" class="my-3 mr-2" >
            Volver
          </v-btn>

          <v-btn color="primary" @click="nextImage()" class="my-3" >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>

        </v-col>
      </v-row>


    </v-overlay>

  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'

export default {
  name: `Gallery`,
  components: {
    LoadingIndicator,
  },
  computed: {
    apiURL() {
      return process.env.VUE_APP_APIURL
    },
  },
  data: () => ({
    //state
    isLoading: true,
    isLightbox: false,
    //data
    gallery: [],
    lightboxIdx: null,
    lightboxAlbum: null,
    lightboxImg: null,
  }),
  methods: {
    async loadGallery() {
      try {
      
        this.isLoading = true
      
        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/gallery`)
        this.gallery = (resp.data.data.length > 0) ? resp.data.data : []
      
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    openLightbox(url, idx, albumIdx) {
      this.lightboxAlbum = albumIdx
      this.lightboxIdx = idx
      this.lightboxImg = url
      this.isLightbox = true
    },
    nextImage() {

      if(this.lightboxIdx < this.gallery[this.lightboxAlbum].imgURL.length - 1) {
        this.lightboxIdx++
      } else {
        this.lightboxIdx = 0
      }

      this.lightboxImg = `${this.apiURL}/uploads/${this.gallery[this.lightboxAlbum].imgURL[this.lightboxIdx]}`
    },
    prevImage() {

      if(this.lightboxIdx > 0) {
        this.lightboxIdx--
      } else {
        this.lightboxIdx = this.gallery[this.lightboxAlbum].imgURL.length - 1
      }

      this.lightboxImg = `${this.apiURL}/uploads/${this.gallery[this.lightboxAlbum].imgURL[this.lightboxIdx]}`
    },
  },
  mounted() {
    this.loadGallery()
  }
}
</script>

<style lang="scss" scoped>

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}

.pointer {
  cursor: pointer;
}

.borderleftdecor {
  border-left: 5px solid #1582ff;
}

.gradBackground {
  height: 100%;
  background: #f0f0f0;
}

.maxwscreen {
  max-width: 70vw;
}

</style>